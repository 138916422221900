// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Pagination, Autoplay, A11y, Navigation } from 'swiper/modules';

import 'swiper/scss';
import 'swiper/scss/a11y'
import 'swiper/scss/pagination'

const SwiperInit = () => {
  const sliderSelector = '.swiper'
  const defaultOptions = {
    modules: [Pagination, Autoplay, A11y, Navigation],
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoHeight: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.swiper-button-next-homeStage', // Match your custom class
      prevEl: '.swiper-button-prev-homeStage', // Match your custom class
    }
  };

  const swiperNodes = document.querySelectorAll(sliderSelector);

  [].forEach.call(swiperNodes, ( swiperInstance ) => {
    const data = swiperInstance.getAttribute('data-swiper-settings') || {};

    if (data) {
      const dataOptions = JSON.parse(data);

      swiperInstance.options = Object.assign({}, defaultOptions, dataOptions);
      const swiper = new Swiper(swiperInstance, swiperInstance.options);
      swiperInstance.addEventListener('mouseenter', () => swiper.autoplay.stop());
      swiperInstance.addEventListener('mouseleave', () => swiper.autoplay.start());
    }
  });
}
export default SwiperInit;
