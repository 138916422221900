// import from 'bootstrap'
import {
  Modal,
  Offcanvas, // mobile Nav
  Tab,       // Tab CE
  Popover,
  Dropdown,
  Toast,
  Tooltip,
  Collapse,   // mobile Facets
  ScrollSpy,
  Button,
  Alert
} from 'bootstrap'

//require('jquery.mmenu/dist/jquery.mmenu.all');

//import MmenuLight from 'mmenu-light';

import SwiperInit from './js/swiper-init'
SwiperInit();

// import custom SCSS
import './scss/main.scss';

// Open burgermenu dropdown
document.querySelectorAll('.dropdown-arrow').forEach(function (element) {
    element.addEventListener('click', function () {
      const dropdownMenu = this.parentElement.nextElementSibling;
      if (dropdownMenu.classList.contains('show')) {
        dropdownMenu.style.maxHeight = dropdownMenu.scrollHeight + 'px';
        setTimeout(function() {
          dropdownMenu.style.maxHeight = '0';
        }, 10);
        dropdownMenu.classList.remove('show');
      } else {
        dropdownMenu.classList.add('show');
        dropdownMenu.style.maxHeight = '0';
        setTimeout(function() {
          dropdownMenu.style.maxHeight = dropdownMenu.scrollHeight + 'px';
        }, 10);
      }
    });
  });

  // Close dropdown if clicked outside
  document.addEventListener('click', function (e) {
    var isClickInside = document.querySelector('.dropdown').contains(e.target);
    if (!isClickInside) {
      document.querySelectorAll('.dropdown-menu').forEach(function (dropdown) {
        if (dropdown.classList.contains('show')) {
          dropdown.style.maxHeight = dropdown.scrollHeight + 'px';
          setTimeout(function() {
            dropdown.style.maxHeight = '0';
          }, 10);
          dropdown.classList.remove('show');
        }
      });
    }
  });

  /* Script of datalayer push for newsletter */
document.addEventListener("DOMContentLoaded", function () {
  const button = document.querySelector(".newsletter-submit");
  const emailField = document.getElementById("newsletter-email");
  const EmailLink = document.querySelector('a[href="mailto:newsletter@bildungsklick.de"]');

  button.addEventListener("click", function () {
    window.dataLayer = window.dataLayer || [];
    const emailValue = emailField.value.trim();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const checkboxes = document.querySelectorAll('.checkboxNewsletter input[type="checkbox"]');
    let checkedValues = [];
    let checkedStatus = false;
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        checkedValues.push(checkbox.value); // Add the value if checked
      }
    });
    checkedStatus = checkedValues.length > 0;
    const checkedResult = checkedValues.join(', ');

    if (!emailValue) {
      window.dataLayer.push({
        event: 'newsletter_interactions',
        checkbox_name: checkedResult,
        checkbox_checked: checkedStatus,
        status: 'raw attempt',
      });
    } else if (!emailRegex.test(emailValue)) {
      window.dataLayer.push({
        event: 'newsletter_interactions',
        checkbox_name: checkedResult,
        checkbox_checked: checkedStatus,
        status: 'raw attempt',
      });
    } else {
      window.dataLayer.push({
        event: 'newsletter_interactions',
        checkbox_name: checkedResult,
        checkbox_checked: checkedStatus,
        status: 'form submitted',
      });
    }
  });

  EmailLink.addEventListener("click", function (event) {
    event.preventDefault();
    window.dataLayer.push({
      event: 'newsletter_interactions',
      status: 'Unsubscribe',
    });
  });

});
